
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { useRole, usePermissions, useCreateRole, useUpdateRole } from '@/composables/api';
import { ResponseError } from '@/services/api';
import { PartialRole } from '@/interfaces/Role';
import useFormErrors from '@/utils/validate';
import PermissionsTree from '../components/PermissionsTree.vue';

const DEFAULT_FORM_VALUES: Omit<PartialRole, 'permissions'> & { permissions: number[] } = {
  name: '',
  permissions: []
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PermissionsTree
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const roleId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { data } = useRole({ roleId }, { enabled: isEdit.value });
    const checkedPermissions = ref([]);
    const defaultExpandedKeys = ref([]);
    const defaultCheckedKeys = ref([]);

    watch(data, ({ data }) => {
      formValues.value = {
        ...data,
        permissions: data.permissions.map(({ id }) => id)
      };
      const permissions = data.permissions.map(p => p.id);
      defaultExpandedKeys.value = permissions;
      defaultCheckedKeys.value = permissions;
    });

    const getCheckedKeys = (checkedKeys: number[]) => {
      checkedPermissions.value = checkedKeys;
    };

    const { isLoading: isCreatedLoading, mutate: create } = useCreateRole();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateRole();
    const { formErrors, bindFormItemError } = useFormErrors();

    const rules = {
      name: [
        {
          required: true,
          trigger: 'blur'
        }
      ],
      permissions: [
        {
          required: false,
          trigger: 'change'
        }
      ]
    };

    watch(checkedPermissions, (value) => {
      formValues.value.permissions = value;
      console.log('formValues', formValues.value);
    });
    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            formValues.value = {
              name: data.value.data.name,
              permissions: checkedPermissions.value
            };
            update({
              roleId,
              data: formValues.value
            }, {
              onSuccess() {
                ElMessage.success('success!');

                router.replace({
                  name: 'list-roles'
                });
              },

              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.errors;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success('success!');

              formValues.value = DEFAULT_FORM_VALUES;
              router.replace({
                name: 'list-roles'
              });
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.errors;
            }
          });
        }
      });
    };

    return {
      data,
      formValues,
      formRef,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      bindFormItemError,
      defaultExpandedKeys,
      defaultCheckedKeys,
      getCheckedKeys
    };
  }
});
